define([
    //"durandal/app",
    //"plugins/dialog",
    "knockout",
    "core/resx",
    //"plugins/router",
    "services/serviceService",
    "helpers/routerHelper",
    "helpers/dateHelper",
    "helpers/labelHelper",
    "helpers/enumHelper",
    "helpers/queryStringHelper",
    "helpers/notificationHelper",
    "services/dispatchService",
    "widgets/dateTimePicker"
], function (/*app, dialog,*/ ko, resx,/* router,*/ serviceService, routerHelper, dateHelper, labelHelper, enumHelper, queryStringHelper, notificationHelper, dispatchService) {
    "use strict";

    var viewModel = (function() {
        var self = null;

        function ctor() {
            self = this;
            //#region properties
            self.resx = resx;
            self.dateHelper = dateHelper;
            self.routerHelper = routerHelper;
            //self.router = router;
            self.labelHelper = labelHelper;
            self.enumPresence = enumHelper.servicePresence();

            self.actions = {};
            self.dispatchId = '';
            self.serviceType = '';
            self.readonly = false;
            self.detail = ko.observable({});
            self.subheaderTitle = '';
            self.subheaderCssClass = '';
            self.date = ko.observable('');

            //#endregion
        }

        //#region private functions
        function bindViewModel(dispatchId, serviceType, querystring) {
            self.dispatchId = dispatchId;
            self.serviceType = serviceType;
            getDataFromQuerystring(querystring);
            self.errorMessage = resx.localize('err_DateGreaterThanNow');
            self.date(dateHelper.getNow());
        }

        function getDataFromQuerystring(querystring) {
            self.detail(JSON.parse(routerHelper.getQuerystring(querystring).detail));
            self.subheaderCssClass = routerHelper.getQuerystring(querystring).subheaderCssClass;
            self.subheaderTitle = routerHelper.getQuerystring(querystring).subheaderTitle;
            self.readonly = queryStringHelper.parseReadonly(querystring);
            self.actions = {
                save: function (selectedDate) {
                    self.saveDate(selectedDate);
                }
            };
        }

        //#endregion
        //#region public functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchId, params.serviceType, params.q);
        };

        ctor.prototype.genUrl = function () {
            return routerHelper.navigateTo('Service_Detail_Information', self.serviceType, self.dispatchId);
        };
        ctor.prototype.saveDate = function(selectedDate) {
            if (self.detail().AssignedDate) {
                var assignedDate = dateHelper.dateFromUTC(self.detail().AssignedDate);
                selectedDate = dateHelper.setHoursMinOnDate(selectedDate, assignedDate.getHours(), assignedDate.getMinutes());
            }
            else {
                var unplannifiedDate = dateHelper.dateFromUTC(selectedDate);
                if (dateHelper.isToday(unplannifiedDate)) {
                    unplannifiedDate = new Date();
                }
                selectedDate = unplannifiedDate;
            }

            var assDate = dateHelper.formatDateToSend(selectedDate);
            self.saveAccept(assDate);
        };

        ctor.prototype.saveAccept = function (assignDate) {
            dispatchService.setServiceCallAccept(self.dispatchId, assignDate)
                .done(function() {
                    var travelStartDate = self.detail().TravelStartDate || assignDate;
                    var url = routerHelper.navigateTo('Service_Detail_Action_Road', self.serviceType, self.dispatchId, travelStartDate, assignDate, self.detail().TimeIncrement);
                    url += routerHelper.addQuerystring({ readonly: false });
                    routerHelper.navigate(url, {replace: true});
                });
        };
        return ctor;
    })();
    return viewModel;

});