import { PLATFORM } from "aurelia-framework";

export function configure(config) {
    config.globalResources([
        // Les buttons
        PLATFORM.moduleName("./buttons/button"),
        PLATFORM.moduleName("./buttons/collapse-all"),
        PLATFORM.moduleName("./buttons/daily-entry-button"),
        PLATFORM.moduleName("./buttons/dropdown-button"),
        PLATFORM.moduleName("./buttons/expandable-button/expandable-button"),
        PLATFORM.moduleName("./buttons/file-select-button"),
        PLATFORM.moduleName("./buttons/radio-button"),
        PLATFORM.moduleName("./buttons/radio-button-new"),
        PLATFORM.moduleName("./buttons/save-button"),
        PLATFORM.moduleName("./buttons/scanner-button"),
        PLATFORM.moduleName("./buttons/ma-link-button"),

        // Fragments
        PLATFORM.moduleName("./fragments/auto-show-section"),
        PLATFORM.moduleName("./fragments/bonus-editor"),
        PLATFORM.moduleName("./fragments/calendar"),
        PLATFORM.moduleName("./fragments/document-list"),
        PLATFORM.moduleName("./fragments/file-thumbnail-list"),
        PLATFORM.moduleName("./fragments/link-list"),
        PLATFORM.moduleName("./fragments/location-view"),
        PLATFORM.moduleName("./fragments/message-well"),
        PLATFORM.moduleName("./fragments/nodata-placeholder"),
        PLATFORM.moduleName("./fragments/spinner"),
        PLATFORM.moduleName("./fragments/procore/synchronization-result-entity-table"),
        PLATFORM.moduleName("./fragments/procore/synchronization-result-entity-statuses"),
        PLATFORM.moduleName("./fragments/procore/synchronization-to-maestro-properties-table"),
        PLATFORM.moduleName("./fragments/procore/synchronization-to-procore-properties-table"),

        PLATFORM.moduleName("./fragments/work-order/work-order-maintenance-measures"),
        PLATFORM.moduleName("./fragments/work-order/work-order-maintenance-task"),
        // Form-controls
        PLATFORM.moduleName("./form-controls/catalog-item-picker"),
        PLATFORM.moduleName("./form-controls/currency-textbox"),
        PLATFORM.moduleName("./form-controls/checkbox-fc"),
        PLATFORM.moduleName("./form-controls/checkbox2-fc"),
        PLATFORM.moduleName("./form-controls/date-picker"),
        PLATFORM.moduleName("./form-controls/date-time-picker"),
        PLATFORM.moduleName("./form-controls/dropdown"),
        PLATFORM.moduleName("./form-controls/form-group"),
        PLATFORM.moduleName("./form-controls/integer-picker"),
        PLATFORM.moduleName("./form-controls/location-picker"),
        PLATFORM.moduleName("./form-controls/lister"),
        PLATFORM.moduleName("./form-controls/multiline-textbox"),
        PLATFORM.moduleName("./form-controls/number-picker"),
        PLATFORM.moduleName("./form-controls/rate-type-picker"),
        PLATFORM.moduleName("./form-controls/textbox"),
        PLATFORM.moduleName("./form-controls/textbox2"),
        PLATFORM.moduleName("./form-controls/text-label"),
        PLATFORM.moduleName("./form-controls/time-picker"),
        PLATFORM.moduleName("./form-controls/ma-textarea"),
        PLATFORM.moduleName("./form-controls/rich-text"),
        PLATFORM.moduleName("./form-controls/documents-add"),

        // Headers, navbar, footers, ...
        PLATFORM.moduleName("./headers/field-service-header"),
        PLATFORM.moduleName("./headers/page-header"),
        PLATFORM.moduleName("./headers/page-context-toolbar"),
        PLATFORM.moduleName("./headers/project-header"),
        PLATFORM.moduleName("./headers/receipt-of-goods-header"),
        PLATFORM.moduleName("./headers/inventory-project-transfer-header"),
        PLATFORM.moduleName("./headers/inspection-header"),
        PLATFORM.moduleName("./headers/inventory-site-transfer-header"),
        PLATFORM.moduleName("./headers/material-order-header"),
        PLATFORM.moduleName("./headers/service-header"),
        PLATFORM.moduleName("./headers/service-quotation-header"),
        PLATFORM.moduleName("./headers/service-equipment-maintenance-header"),
        PLATFORM.moduleName("./headers/shop-drawing-header"),
        PLATFORM.moduleName("./headers/status-footer"),
        PLATFORM.moduleName("./headers/toolbar-button"),
        PLATFORM.moduleName("./headers/toolbar"),
        PLATFORM.moduleName("./headers/work-order-maintenance-header"),
        PLATFORM.moduleName("./headers/image-editor-header"),
        PLATFORM.moduleName("./headers/timesheet-header"),

        // User input
        PLATFORM.moduleName("./inputs/catalog-picker"),
        PLATFORM.moduleName("./inputs/check-list"),
        PLATFORM.moduleName("./inputs/checkbox"),
        PLATFORM.moduleName("./inputs/checkbox2"),
        PLATFORM.moduleName("./inputs/counter-editor"),
        PLATFORM.moduleName("./inputs/equipment-counter-editor"),
        PLATFORM.moduleName("./inputs/i-mask-input"),
        PLATFORM.moduleName("./inputs/ma-select"),
        PLATFORM.moduleName("./inputs/predefined-sentences"),
        PLATFORM.moduleName("./inputs/predefined-sentences-button"),
        PLATFORM.moduleName("./inputs/multi-select-days"),
        PLATFORM.moduleName("./inputs/select2"),
        PLATFORM.moduleName("./inputs/contract-picker"),
        PLATFORM.moduleName("./inputs/specification-picker"),
        PLATFORM.moduleName("./inputs/search-bar"),
        PLATFORM.moduleName("./inputs/toggle"),
        PLATFORM.moduleName("./inputs/pay-period-picker"),

        // UI layout
        PLATFORM.moduleName("./layout/panel"),
        PLATFORM.moduleName("./layout/swipe-to-delete"),
        PLATFORM.moduleName("./layout/tab-control"),
        PLATFORM.moduleName("./layout/tab"),
        PLATFORM.moduleName("./layout/drawer"),
        PLATFORM.moduleName("./layout/ma-card/ma-card"),
        PLATFORM.moduleName("./layout/ma-gallery/ma-gallery"),
        PLATFORM.moduleName("./layout/ma-thumbnail/ma-thumbnail"),
        PLATFORM.moduleName("./layout/ma-messages/ma-messages"),

        PLATFORM.moduleName("./others/ma-inspections"),
        PLATFORM.moduleName("./others/ma-add-note"),
        PLATFORM.moduleName("./others/ma-signature"),
        PLATFORM.moduleName("./others/ma-signature-and-pad"),
        PLATFORM.moduleName("./others/ma-work-task-index"),
        PLATFORM.moduleName("./others/ma-work-task-edit"),
        PLATFORM.moduleName("./others/ma-document"),

        // Components qui ne seront pas réutilisés
        PLATFORM.moduleName("./others/enter-time"),
        PLATFORM.moduleName("./others/home-sections-button"),
        PLATFORM.moduleName("./others/loading-overlay"),
        PLATFORM.moduleName("./others/main-menu"),
        PLATFORM.moduleName("./others/notification-button"),
        PLATFORM.moduleName("./others/scanner"),
        PLATFORM.moduleName("./others/signature-pad"),

        // Page entières
        PLATFORM.moduleName("./pages/material-order-item"),
        PLATFORM.moduleName("./pages/material-order-item-multi"),
        PLATFORM.moduleName("./pages/material-requisition-item"),
        PLATFORM.moduleName("./pages/material-requisition-item-multi"),
        PLATFORM.moduleName("./pages/material-billing-edit"),
        PLATFORM.moduleName("./pages/material-billing-edit-multi"),
        PLATFORM.moduleName("./pages/material-order-summary-component"),
        PLATFORM.moduleName("./pages/material-requisition"),
        PLATFORM.moduleName("./pages/material-requisition-edit"),
        PLATFORM.moduleName("./pages/hours-summary"),
        PLATFORM.moduleName("./pages/contact-details"),
        PLATFORM.moduleName("./pages/contact"),

        // PROCORE
        PLATFORM.moduleName("./ag-grid/grids/procore/error-grid/procore-error-grid"),

        // Excel Exporter
        PLATFORM.moduleName("./excel-exporter/excel-exporter"),
        PLATFORM.moduleName("./pages/additional-fields/additional-fields"),

        // Services component
        PLATFORM.moduleName("./pages/services/equipment/equipment-line"),
        PLATFORM.moduleName("./pages/services/equipment/equipment-button/equipment-button"),
        PLATFORM.moduleName("./pages/services/equipment/equipment-camera/equipment-camera"),

        PLATFORM.moduleName("./pages/services/equipment-details/equipment-details-informations"),
        PLATFORM.moduleName("./pages/services/equipment-details/equipment-details-history"),

        // Component
        PLATFORM.moduleName("./image-editor/ma-image-editor"),

    ]);
}
