define([
    "knockout",
    "underscore",
    "jquery",
    //"plugins/router",
    "helpers/labelHelper",
    "helpers/routerHelper",
    "helpers/documentHelper",
    "repositories/settingRepository",
    "services/defaultService",
    "services/employeeService",
    "select2",
    "widgets/pages/skillCertificate",
    "widgets/serviceHeader"
], function (ko, _, jQuery,/* router,*/ labelHelper, routerHelper, documentHelper, settings, defaultValues, employeeService) {
    "use strict";



    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.dispatchId = ko.observable();
            self.actions = ko.observable();
            self.serviceType = ko.observable();
            self.isProjectResponsible = false;
            self.readyonly = true;
            //#endregion
        }

        //#region Private Functions
        function getEmployeeUrl(emp) {
            return "#";
            //return '#project/' +
            //    encodeURIComponent(self.dispatchProjectCode()) +
            //    '/dailyEntry/' +
            //    self.dispatchDate() +
            //    '/employee/' +
            //    emp.Id +
            //    '/' +
            //    emp.DispatchId +
            //    routerHelper.addQuerystring({ isProjectResponsible: self.isProjectResponsible, readonly: self.readonly });
        }

        function loadData() {
            self.actions = {
                getEmployeesForDispatch: employeeService.getEmployeesForDispatch.bind(null, self.dispatchId()),
                getEmployeeUrl: getEmployeeUrl,
                addDispatchIdToUrl: false
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            self.dispatchId(params.dispatchId);
            self.serviceType(params.serviceType);

            var paramsQs = routerHelper.getQuerystring(params.q);
            self.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
            self.readonly = (paramsQs && paramsQs.readonly === "true") || false;

            return loadData();
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
